@import url("https://fonts.googleapis.com/css?family=Open+Sans&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
}
html {
  scroll-behavior: smooth;
}
body {
  overflow-x: hidden;
}
