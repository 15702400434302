@import url(https://fonts.googleapis.com/css?family=Open+Sans&display=swap);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
}
html {
  scroll-behavior: smooth;
}
body {
  overflow-x: hidden;
}

.icons {
  background-color: black;
  height: 34px;
}
.sp-top-bar li {
  list-style-type: none;
  display: inline;
  padding: 10px;
  margin: 5px;
  cursor: pointer;
  font-size: 18px;
  color: white;
}
a {
  padding: 5px;
  text-decoration: none;
  color: white;
}

i {
  margin-right: 10px;
}
.navbar-logo {
  padding: 15px;
  color: #fff;
  /* pointer-events: none; */
}
.log {
  font-size: 22px;
  margin-left: 8px;
  font-weight: 1000;
  font-family: cursive;
  cursor:pointer ;

}
.log:hover {
  font-size: 22px;
  margin-left: 8px;
  font-weight: 1000;
  color: #fff;
  font-family: cursive;
  cursor:pointer ;

}
.navbar-mainbg {
  background: #c33764;
  background: linear-gradient(
    to right,
    #1d2671,
    #c33764
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  padding: 0px;
  position: -webkit-sticky;
  position: sticky;
  z-index: 100;
  top: 0;
}
#navbarSupportedContent {
  overflow: hidden;
  position: relative;
}
#navbarSupportedContent ul {
  padding: 0px;
  margin: 0px;
}
#navbarSupportedContent ul li a i {
  margin-right: 10px;
}
#navbarSupportedContent li {
  list-style-type: none;
  float: left;
}
#navbarSupportedContent ul li a {
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
  font-size: 15px;
  display: block;
  padding: 20px 20px;
  transition-duration: 0.6s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  position: relative;
}
#navbarSupportedContent > ul > li.active > a {
  color: #000000;
  background-color: transparent;
  transition: all 0.7s;
  font-size: 15px;
  margin-right: 6px;
}
#navbarSupportedContent a:not(:only-child):after {
  content: "\f105";
  position: absolute;
  right: 20px;
  top: 10px;
  font-size: 14px;
  font-family: "Font Awesome 5 Free";
  display: inline-block;
  padding-right: 3px;
  vertical-align: middle;
  font-weight: 900;
  transition: 0.5s;
}
#navbarSupportedContent .active > a:not(:only-child):after {
  transform: rotate(90deg);
}

.hori-selector {
  display: inline-block;
  position: absolute;
  height: 100%;
  top: 0px;
  left: 0px;
  transition-duration: 2s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  background-color: #fff;
  border-top-left-radius: 34px;
  border-top-right-radius: 34px;
  margin-top: 1px;
}
.hori-selector .right,
.hori-selector .left {
  position: absolute;
  width: 25px;
  height: 25px;
  background-color: #fff;
  bottom: 10px;
}
.hori-selector .right {
  right: -4px;
}
.hori-selector .left {
  left: -4px;
}
.hori-selector .right:before,
.hori-selector .left:before {
  content: "";
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #fff;
}
.hori-selector .right:before {
  bottom: 0;
  right: -15px;
}
.hori-selector .left:before {
  bottom: 0;
  left: -15px;
}
@media (max-width: 991px) {
  #navbarSupportedContent ul li a {
    padding: 12px 30px;
  }
  .hori-selector {
    margin-top: 0px;
    margin-left: 10px;
    border-radius: 0;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
  }
  .hori-selector .left,
  .hori-selector .right {
    right: 10px;
  }
  .hori-selector .left {
    top: -25px;
    left: auto;
  }
  .hori-selector .right {
    bottom: -25px;
  }
  .hori-selector .left:before {
    left: -25px;
    top: -25px;
  }
  .hori-selector .right:before {
    bottom: -25px;
    left: -25px;
  }
}

.footer-info {
  width: 90%;
  display: flex;
  margin: 0 auto;
  padding: 50px 0;
}
.footer-info a {
  text-decoration: none;
  transition: 0.5s;
  color: #fff;
}
.footer-info ul,
li {
  list-style-type: none;
}
.footer-info li {
  display: block;
}
.social-media {
  margin-top: 30px;
}
.social-media ul {
  display: flex;
}
.social-media ul li {
  margin-right: 50px;
  width: 50px;
  height: 50px;
  padding-top: 12px;
  background-color: transparent;
  border: 1px solid #fff;
  text-align: center;
  border-radius: 15px;
}
.link ul li a {
  display: block;
  margin-bottom: 15px;
  font-size: 18px;
}
.link ul li a:hover {
  color: #000;
}
.content ul li {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.content ul li span {
  margin-right: 15px;
}
.social-media ul li:hover {
  background-color: #fff;
  color: #000;
  transition: 1s;
  cursor: pointer;
}

.footer-info .footer-width {
  padding: 0 15px;
}
.footer-info h2 {
  margin-bottom: 20px;
  color: #d04ed6;
}
.footer {
  background: #ad5389;
  background: linear-gradient(to right, #ad5389, #3c1053);
  -webkit-clip-path: polygon(0% 5%, 15% 0%, 100% 10%, 100% 100%, 0% 100%);
          clip-path: polygon(0% 5%, 15% 0%, 100% 10%, 100% 100%, 0% 100%);
}
.about,
.contactt {
  width: 40%;
}
.link {
  width: 20%;
}
.copy-right {
  padding: 15px 0;
  text-align: center;
  background: #ad5389;
  background: linear-gradient(to right, #ad5389, #3c1053);
  height: 50px;
}
.copy-right p {
  font-size: 20px;
  color: #fff;
}
@media screen and (max-width: 992px) {
  .about,
  .contactt {
    width: 35%;
  }
  .link {
    width: 30%;
  }
}
.logoo {
  width: 15rem;
}
@media screen and (max-width: 767px) {
  .about,
  .contactt,
  .link {
    width: 100%;
    margin-bottom: 30px;
  }
  .footer-info {
    flex-direction: column;
  }
}

.licontact {
  list-style-type: none;
  display: inline;
  padding: 10px;
  margin: 5px;
  cursor: pointer;
  font-size: 18px;
  color: white;
}

/* .firsti,
.secondi,
.thirdi,
.fourthi,
.fifthi,
.sixthi {
  width: 35.33%;
  height: 45.33%;
  margin-left: 86px;
  margin-bottom: 30px;
}
.first,
.second,
.third,
.fourth,
.fifth,
.sixth {
  margin-top: 34px;
  padding-left: 100px;
  cursor: pointer;
}
.firstp,
.secondp,
.thirdp,
.fourthp,
.fifthp,
.sixthp {
  font-family: cursive;
  font-size: 19px;
  letter-spacing: 3px;
  color: #de4530;
}
.para {
  margin-top: 5px;
  text-transform: uppercase;
  letter-spacing: 5px;
  font-size: 40px;
  font-weight: 800;
  color: #1d6aaa;

  font-family: Georgia, serif;
}
.paraa {
  margin-top: 45px;
  margin-left: 490px;
  text-transform: uppercase;
  letter-spacing: 15px;
  font-size: 40px;
  font-weight: 700;
  color: #1d6aaa;
  font-family: Georgia, serif;
  animation: text 6s 1;
}

@keyframes text {
  0% {
    color: white;
    margin-bottom: -100px;
  }
  30% {
    letter-spacing: 25px;
    margin-bottom: -40px;
  }
  85% {
    letter-spacing: 8px;
    margin-bottom: -40px;
  }
} */

.Service {
  height: auto;
  width: 100vw;
  display: -ms-grid;
  display: grid;
  place-items: center;
  background: #fff;
}

.Service h1 {
  font-size: 4rem;
  margin: 1rem 0;
  color: #302b63;
  text-transform: uppercase;
  letter-spacing: 15px;
  font-size: 40px;
  font-weight: 700;
  font-family: Georgia, serif;
  -webkit-animation: text 6s 1;
          animation: text 6s 1;
}

@-webkit-keyframes text {
  0% {
    color: white;
    margin-bottom: -100px;
  }
  30% {
    letter-spacing: 25px;
    margin-bottom: -40px;
  }
  85% {
    letter-spacing: 8px;
    margin-bottom: -40px;
  }
}

@keyframes text {
  0% {
    color: white;
    margin-bottom: -100px;
  }
  30% {
    letter-spacing: 25px;
    margin-bottom: -40px;
  }
  85% {
    letter-spacing: 8px;
    margin-bottom: -40px;
  }
}

.Service .box-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 90vw;
}

.Service .box-container .box {
  height: 20rem;
  width: 18rem;
  background: #abbaab; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #ffffff,
    #abbaab
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  display: -ms-grid;
  display: grid;
  place-items: center;
  margin: 2rem;
  cursor: pointer;
  opacity: 0.7;
}

.Service .box-container .box:hover {
  transform: scale(0.9);
  box-shadow: 0 0 2rem #000;
  transition: 0.7s;
  opacity: 1;
}

.Service .box-container .box img {
  height: 7rem;
}

.Service .box-container .box p {
  color: #780206;
  padding: 1rem;
  font-size: 1.5rem;
  text-align: center;
}

/* .eimg {
  height: 524px;
  margin-left: -157px;
  margin-top: -11px;
  margin-right: 100px;
}
.comp {
  padding-left: 62px;

  margin-top: 50px;
  margin-left: 152px;
  margin-right: -129px;
}
.fname {
  padding-bottom: 4px;
  margin-bottom: 15px;
  height: 50px;
}
label {
  margin-right: 11px;
  margin-left: 145px;
  margin-top: 30px;
  text-align: center;
  font-family: cursive;
  font-size: 20px;
  font-weight: 300;
}
.emaill {
  padding-bottom: 4px;
  margin-bottom: 15px;
  height: 50px;
}
.bttn {
  width: 138px;
  height: 56px;
  margin-left: 460px;
  margin-top: 21px;
  background-color: #1d6aaa;
  color: white;
  font-size: 25px;
  letter-spacing: 2px;
  font-family: cursive;
}
.paras {
  margin-top: 5px;
  text-transform: uppercase;
  letter-spacing: 5px;
  font-size: 40px;
  font-weight: 800;
  color: red;

  font-family: Georgia, serif;
}
.bttn {
  margin-bottom: 10px;
}
.bttn:hover {
  padding-top: 3px;
} */
.contact-section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eeeee4;
}

.contact-info {
  color: #063970;
  max-width: 500px;
  line-height: 65px;
  padding-left: 50px;
  font-size: 18px;
}

.contact-info i {
  margin-right: 20px;
  font-size: 25px;
}

.contact-form {
  max-width: 700px;
  margin-right: 50px;
}

.contact-info,
.contact-form {
  flex: 1 1;
}
.icoon {
  margin-right: 14px;
}
.contact-form h2 {
  color: #063970;
  text-align: center;
  font-size: 35px;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 30px;
  margin-top: 5px;
}

.contact-form .text-box {
  background: #fff;
  color: #000;
  border: none;
  width: calc(50% - 10px);
  height: 50px;
  padding: 12px;
  font-size: 15px;
  border-radius: 5px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  opacity: 0.9;
}

.contact-form .text-box:first-child {
  margin-right: 15px;
}

.contact-form textarea {
  background: #fff;
  color: #000;
  border: none;
  width: 100%;
  padding: 12px;
  font-size: 15px;
  min-height: 200px;
  max-height: 400px;
  resize: vertical;
  border-radius: 5px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  opacity: 0.9;
}

.contact-form .send-btn {
  float: right;
  background: #2e94e3;
  color: #fff;
  border: none;
  width: 120px;
  height: 40px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
  margin-bottom: 7px;
  transition-property: background;
}

.contact-form .send-btn:hover {
  background: #0582e3;
}

@media screen and (max-width: 950px) {
  .contact-section {
    flex-direction: column;
  }

  .contact-info,
  .contact-form {
    margin: 30px 50px;
  }

  .contact-form h2 {
    font-size: 30px;
  }

  .contact-form .text-box {
    width: 100%;
  }
}

/*css for alert messages*/

.alert-success {
  z-index: 1;
  background: #d4edda;
  font-size: 18px;
  padding: 20px 40px;
  min-width: 420px;
  position: fixed;
  right: 0;
  top: 10px;
  border-left: 8px solid #3ad66e;
  border-radius: 4px;
}

.alert-error {
  z-index: 1;
  background: #fff3cd;
  font-size: 18px;
  padding: 20px 40px;
  min-width: 420px;
  position: fixed;
  right: 0;
  top: 10px;
  border-left: 8px solid #ffa502;
  border-radius: 4px;
}

.jumbotron {
  background-image: url(/static/media/auto2.219d4005.jpg);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0 !important;
}
.bg-cover {
  background-size: cover !important;
}
.scroll-down {
  position: absolute;
  bottom: 41%;
  left: 50%;
  transform: translateX(-50%);
  height: 4rem;
  width: 2rem;
  border: 0.3rem solid #fff;
  border-radius: 5rem;
  cursor: pointer;
}
.overlay h1 {
  font-family: monospace;
}
.scroll-down::before {
  content: "";
  position: absolute;
  bottom: 40%;
  left: 50%;
  transform: translateX(-50%);
  height: 2rem;
  width: 1rem;
  background: #fff;
  border-radius: 5rem;
  -webkit-animation: scroll 1s alternate infinite;
  animation: scroll 1s alternate infinite;
}
@-webkit-keyframes scroll {
  from {
    bottom: 40%;
  }
  to {
    bottom: 10%;
  }
}

@keyframes scroll {
  from {
    bottom: 40%;
  }
  to {
    bottom: 10%;
  }
}
@media (max-width: 768px) {
  .scroll-down {
    position: absolute;
    bottom: 23%;
    left: 50%;
    transform: translateX(-50%);
    height: 4rem;
    width: 2rem;
    border: 0.3rem solid #fff;
    border-radius: 5rem;
    cursor: pointer;
  }

  .scroll-down::before {
    content: "";
    position: absolute;
    bottom: 40%;
    left: 50%;
    transform: translateX(-50%);
    height: 2rem;
    width: 1rem;
    background: #fff;
    border-radius: 5rem;
    -webkit-animation: scroll 1s alternate infinite;
    animation: scroll 1s alternate infinite;
  }
  @-webkit-keyframes scroll {
    from {
      bottom: 40%;
    }
    to {
      bottom: 10%;
    }
  }

  @keyframes scroll {
    from {
      bottom: 40%;
    }
    to {
      bottom: 10%;
    }
  }
}

.slides {
  background: #f7f7f7;
  height: 450px;
}
.imagebox img {
  margin-top: 7%;
  margin-left: 20%;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
  transition: 1s;
  width: 72%;
}
.imagebox img:hover {
  -webkit-filter: grayscale(0%);
          filter: grayscale(0%);
  transform: scale(1.2);
}
.content {
  margin-top: 5%;
  text-align: center;
}
.content h2 {
  padding-top: 2%;
  padding-bottom: 2%;
  font-weight: 600;
  line-height: 122%;
  font-size: 39px;
  font-family: Georgia, serif;
  color: #302b63;
}
.content p {
  color: #780206;
  letter-spacing: 3px;
  font-size: 20px;
}
@media screen and (max-width: 768px) {
  .slides {
    background: #f7f7f7;
    height: 450px;
  }
  .imagebox img {
    margin-top: 7%;
    margin-left: 20%;
    width: 72%;
  }

  .content {
    text-align: center;
  }
  .content h2 {
    padding-top: 2%;
    padding-bottom: 2%;
    font-weight: 600;
    line-height: 122%;
    font-size: 80%;
  }
  .content p {
    font-size: 80%;
  }
}
@media screen and (min-width: 768px) and(max-width:946px) {
  .content {
    display: block;
  }
  .content p {
    font-size: 70%;
    margin-bottom: 5%;
  }
}

.section {
  width: 100%;
  min-height: 70vh;
  background: #f7f7f7;
}
.inner-container {
  width: 90%;
  display: block;
  margin: auto;
  padding-top: 60px;
}
.content-section {
  float: left;
  width: 55%;
}
.imagge-section {
  float: right;
  width: 40%;
}
.imagge-section img {
  width: 100%;
  height: auto;
  padding-top: 61px;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
}
.imagge-section img {
  -webkit-filter: grayscale(0%);
          filter: grayscale(0%);
  transform: scale(1.2);
  transition: 1s;
}
.content-section .title {
  /* text-transform: uppercase; */
  font-size: 20px;
  text-align: center;
}
.content-section .title h1 {
  font-weight: 800;
  font-family: monospace;
  color: #302b63;
  border-bottom: 1px solid black;
  width: 176px;
  margin: auto;
  -webkit-animation: textt 6s 1;
          animation: textt 6s 1;
}

@-webkit-keyframes textt {
  0% {
    color: white;
  }
  30% {
    letter-spacing: 15px;
  }
  85% {
    letter-spacing: 8px;
  }
}

@keyframes textt {
  0% {
    color: white;
  }
  30% {
    letter-spacing: 15px;
  }
  85% {
    letter-spacing: 8px;
  }
}
.content-section .contennt h3 {
  margin-top: 20px;
  color: #780206;
  font-size: 21px;
  font-weight: 700;
}
.content-section .contennt p {
  margin-top: 10px;
  font-family: sans-serif;
  font-size: 17px;
  line-height: 2;
  color: #780206;
}
.content-section .contennt .button {
  margin-top: 30px;
}
.content-section .contennt .button {
  background-color: #3d3d3d;
  padding: 12px 40px;
  text-decoration: none;
  color: #fff;
  font-size: 25px;
  letter-spacing: 1.5px;
}
.content-section .contennt .button:hover {
  background: #c33764;
  background: linear-gradient(to right, #1d2671, #c33764);
  color: #fff;
}

@media screen and (max-width: 768px) {
  .inner-container {
    width: 90%;
    display: block;
    margin: auto;
    padding-top: 80px;
  }
  .content-section {
    float: none;
    width: 100%;
    display: block;
    margin: auto;
  }
  .imagge-section {
    float: none;
    width: 100%;
    display: block;
    margin: auto;
  }
  .content-section .title {
    text-align: center;
    font-size: 19px;
  }
  .imagge-section img {
    margin-bottom: 20px;
  }
}

.wrapper {
  -webkit-animation: scrolls 70s linear infinite;
          animation: scrolls 70s linear infinite;
  background: url("https://images.unsplash.com/photo-1465146633011-14f8e0781093?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=3450&q=80"),
    #111111;
  color: #eee;
  height: 100vh;
  min-width: 360px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  perspective: 1000px;
  perspective-origin: 50% 50%;
}

@-webkit-keyframes scrolls {
  100% {
    background-position: 0px -3000px;
  }
}

@keyframes scrolls {
  100% {
    background-position: 0px -3000px;
  }
}

@media (prefers-reduced-motion) {
  .wrapper {
    -webkit-animation: scroll 200s linear infinite;
            animation: scroll 200s linear infinite;
  }
}

@media (min-width: 670px) {
  .title {
    font-size: 5rem;
  }
}
.wrapper section {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1;
}
.linkk {
  border: 2px solid grey;
  width: 69%;
  text-align: center;
  padding: 5%;
  border-radius: 25%;
  text-transform: uppercase;
  font-size: 146%;
  font-weight: 500;
  letter-spacing: 2px;
}
.linkk:hover {
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.5);
  transition: 0.5s;
  color: #fff;
}

@media (max-width: 768px) {
  .linkk {
    border: 2px solid grey;
    width: 63%;
    text-align: center;
    padding: 5%;
    border-radius: 25%;
    text-transform: uppercase;
    font-size: 76%;
    font-weight: 500;
  }
  .linkk:hover {
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.5);

    color: #fff;
  }
  .wrapper {
    width: 100%;
  }
}

