.wrapper {
  animation: scrolls 70s linear infinite;
  background: url("https://images.unsplash.com/photo-1465146633011-14f8e0781093?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=3450&q=80"),
    #111111;
  color: #eee;
  height: 100vh;
  min-width: 360px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  perspective: 1000px;
  perspective-origin: 50% 50%;
}

@keyframes scrolls {
  100% {
    background-position: 0px -3000px;
  }
}

@media (prefers-reduced-motion) {
  .wrapper {
    animation: scroll 200s linear infinite;
  }
}

@media (min-width: 670px) {
  .title {
    font-size: 5rem;
  }
}
.wrapper section {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}
.linkk {
  border: 2px solid grey;
  width: 69%;
  text-align: center;
  padding: 5%;
  border-radius: 25%;
  text-transform: uppercase;
  font-size: 146%;
  font-weight: 500;
  letter-spacing: 2px;
}
.linkk:hover {
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.5);
  transition: 0.5s;
  color: #fff;
}

@media (max-width: 768px) {
  .linkk {
    border: 2px solid grey;
    width: 63%;
    text-align: center;
    padding: 5%;
    border-radius: 25%;
    text-transform: uppercase;
    font-size: 76%;
    font-weight: 500;
  }
  .linkk:hover {
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.5);

    color: #fff;
  }
  .wrapper {
    width: 100%;
  }
}
