/* .firsti,
.secondi,
.thirdi,
.fourthi,
.fifthi,
.sixthi {
  width: 35.33%;
  height: 45.33%;
  margin-left: 86px;
  margin-bottom: 30px;
}
.first,
.second,
.third,
.fourth,
.fifth,
.sixth {
  margin-top: 34px;
  padding-left: 100px;
  cursor: pointer;
}
.firstp,
.secondp,
.thirdp,
.fourthp,
.fifthp,
.sixthp {
  font-family: cursive;
  font-size: 19px;
  letter-spacing: 3px;
  color: #de4530;
}
.para {
  margin-top: 5px;
  text-transform: uppercase;
  letter-spacing: 5px;
  font-size: 40px;
  font-weight: 800;
  color: #1d6aaa;

  font-family: Georgia, serif;
}
.paraa {
  margin-top: 45px;
  margin-left: 490px;
  text-transform: uppercase;
  letter-spacing: 15px;
  font-size: 40px;
  font-weight: 700;
  color: #1d6aaa;
  font-family: Georgia, serif;
  animation: text 6s 1;
}

@keyframes text {
  0% {
    color: white;
    margin-bottom: -100px;
  }
  30% {
    letter-spacing: 25px;
    margin-bottom: -40px;
  }
  85% {
    letter-spacing: 8px;
    margin-bottom: -40px;
  }
} */

.Service {
  height: auto;
  width: 100vw;
  display: -ms-grid;
  display: grid;
  place-items: center;
  background: #fff;
}

.Service h1 {
  font-size: 4rem;
  margin: 1rem 0;
  color: #302b63;
  text-transform: uppercase;
  letter-spacing: 15px;
  font-size: 40px;
  font-weight: 700;
  font-family: Georgia, serif;
  animation: text 6s 1;
}

@keyframes text {
  0% {
    color: white;
    margin-bottom: -100px;
  }
  30% {
    letter-spacing: 25px;
    margin-bottom: -40px;
  }
  85% {
    letter-spacing: 8px;
    margin-bottom: -40px;
  }
}

.Service .box-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 90vw;
}

.Service .box-container .box {
  height: 20rem;
  width: 18rem;
  background: #abbaab; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #ffffff,
    #abbaab
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #ffffff,
    #abbaab
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  display: -ms-grid;
  display: grid;
  place-items: center;
  margin: 2rem;
  cursor: pointer;
  opacity: 0.7;
}

.Service .box-container .box:hover {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
  -webkit-box-shadow: 0 0 2rem #000;
  box-shadow: 0 0 2rem #000;
  transition: 0.7s;
  opacity: 1;
}

.Service .box-container .box img {
  height: 7rem;
}

.Service .box-container .box p {
  color: #780206;
  padding: 1rem;
  font-size: 1.5rem;
  text-align: center;
}
