.footer-info {
  width: 90%;
  display: flex;
  margin: 0 auto;
  padding: 50px 0;
}
.footer-info a {
  text-decoration: none;
  transition: 0.5s;
  color: #fff;
}
.footer-info ul,
li {
  list-style-type: none;
}
.footer-info li {
  display: block;
}
.social-media {
  margin-top: 30px;
}
.social-media ul {
  display: flex;
}
.social-media ul li {
  margin-right: 50px;
  width: 50px;
  height: 50px;
  padding-top: 12px;
  background-color: transparent;
  border: 1px solid #fff;
  text-align: center;
  border-radius: 15px;
}
.link ul li a {
  display: block;
  margin-bottom: 15px;
  font-size: 18px;
}
.link ul li a:hover {
  color: #000;
}
.content ul li {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.content ul li span {
  margin-right: 15px;
}
.social-media ul li:hover {
  background-color: #fff;
  color: #000;
  transition: 1s;
  cursor: pointer;
}

.footer-info .footer-width {
  padding: 0 15px;
}
.footer-info h2 {
  margin-bottom: 20px;
  color: #d04ed6;
}
.footer {
  background: #ad5389;
  background: -webkit-linear-gradient(to right, #ad5389, #3c1053);
  background: linear-gradient(to right, #ad5389, #3c1053);
  clip-path: polygon(0% 5%, 15% 0%, 100% 10%, 100% 100%, 0% 100%);
}
.about,
.contactt {
  width: 40%;
}
.link {
  width: 20%;
}
.copy-right {
  padding: 15px 0;
  text-align: center;
  background: #ad5389;
  background: -webkit-linear-gradient(to right, #ad5389, #3c1053);
  background: linear-gradient(to right, #ad5389, #3c1053);
  height: 50px;
}
.copy-right p {
  font-size: 20px;
  color: #fff;
}
@media screen and (max-width: 992px) {
  .about,
  .contactt {
    width: 35%;
  }
  .link {
    width: 30%;
  }
}
.logoo {
  width: 15rem;
}
@media screen and (max-width: 767px) {
  .about,
  .contactt,
  .link {
    width: 100%;
    margin-bottom: 30px;
  }
  .footer-info {
    flex-direction: column;
  }
}

.licontact {
  list-style-type: none;
  display: inline;
  padding: 10px;
  margin: 5px;
  cursor: pointer;
  font-size: 18px;
  color: white;
}
