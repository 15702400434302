.slides {
  background: #f7f7f7;
  height: 450px;
}
.imagebox img {
  margin-top: 7%;
  margin-left: 20%;
  filter: grayscale(100%);
  transition: 1s;
  width: 72%;
}
.imagebox img:hover {
  filter: grayscale(0%);
  transform: scale(1.2);
}
.content {
  margin-top: 5%;
  text-align: center;
}
.content h2 {
  padding-top: 2%;
  padding-bottom: 2%;
  font-weight: 600;
  line-height: 122%;
  font-size: 39px;
  font-family: Georgia, serif;
  color: #302b63;
}
.content p {
  color: #780206;
  letter-spacing: 3px;
  font-size: 20px;
}
@media screen and (max-width: 768px) {
  .slides {
    background: #f7f7f7;
    height: 450px;
  }
  .imagebox img {
    margin-top: 7%;
    margin-left: 20%;
    width: 72%;
  }

  .content {
    text-align: center;
  }
  .content h2 {
    padding-top: 2%;
    padding-bottom: 2%;
    font-weight: 600;
    line-height: 122%;
    font-size: 80%;
  }
  .content p {
    font-size: 80%;
  }
}
@media screen and (min-width: 768px) and(max-width:946px) {
  .content {
    display: block;
  }
  .content p {
    font-size: 70%;
    margin-bottom: 5%;
  }
}
